/* eslint-disable new-cap */
import vue from 'vue'
import vueRouter from 'vue-router'

vue.use(vueRouter)

const router = new vueRouter(
  {
    mode: 'history',
    routes: [
      {
        path: '',
        name: 'index',
        component: () => import('@/pages/index'),
        meta: {
          title: '飞钉低代码搭建应用平台'
        }
      },
      {
        path: '/product',
        name: 'product',
        component: () => import('@/pages/product.vue'),
        meta: {
          title: '海量应用模板，随需可调'
        }
      },
      {
        path: '/solution',
        name: 'solution',
        component: () => import('@/pages/solution.vue'),
        meta: {
          title: '飞钉客户案例，行业解决方案'
        }
      },
      {
        path: '/school',
        name: 'school',
        component: () => import('@/pages/school.vue'),
        meta: {
          title: '飞钉学堂-视频课，飞钉社区'
        }
      },
      {
        path: '/cooperate',
        name: 'cooperate',
        component: () => import('@/pages/cooperate.vue'),
        meta: {
          title: '飞钉-加入我们'
        }
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/pages/about.vue'),
        meta: {
          title: '飞钉-了解飞钉'
        }
      },
      {
        path: '/help',
        name: 'help',
        component: () => import('@/pages/help.vue'),
        meta: {
          title: '飞钉-帮助中心'
        }
      },
      {
        path: '/programme',
        name: 'programme',
        component: () => import('@/pages/programme.vue'),
        meta: {
          title: '飞钉-企微平台'
        }
      },
      {
        path: '/hr',
        name: 'hr',
        component: () => import('@/pages/hr.vue'),
        meta: {
          title: '人力行政OA系统，绩效、资产、员工管理系统'
        }
      },
      {
        path: '/finance',
        name: 'finance',
        component: () => import('@/pages/finance.vue'),
        meta: {
          title: '财务管理系统，财税一体化管理系统'
        }
      },
      {
        path: '/procure',
        name: 'procure',
        component: () => import('@/pages/procure.vue'),
        meta: {
          title: '进销存管理系统，采购管理系统，生产管理系统，仓库管理系统'
        }
      },
      {
        path: '/inventory',
        name: 'inventory',
        component: () => import('@/pages/inventory.vue'),
        meta: {
          title: '进销存管理系统，采购管理系统，生产管理系统，仓库管理系统'
        }
      },
      {
        path: '/supply',
        name: 'supply',
        component: () => import('@/pages/supply.vue'),
        meta: {
          title: '进销存管理系统，采购管理系统，生产管理系统，仓库管理系统'
        }
      },
      {
        path: '/crm1',
        name: 'crm1',
        component: () => import('@/pages/crm1.vue')
      },
      {
        path: '/produce',
        name: 'produce',
        component: () => import('@/pages/produce.vue'),
        meta: {
          title: '进销存管理系统，采购管理系统，生产管理系统，仓库管理系统'
        }
      },
      {
        path: '/crm',
        name: 'crm',
        component: () => import('@/pages/crm.vue'),
        meta: {
          title: '灵活定制 新一代crm'
        }
      },
      {
        path: '/casedetail',
        name: 'casedetail',
        component: () => import('@/pages/casedetail.vue'),
        meta: {
          title: '案例详情'
        }
      },
      {
        path: '/jingqu',
        name: 'jingqu',
        component: () => import('@/pages/jingqu.vue'),
        meta: {
          title: '飞钉-智慧景区业务线'
        }
      },
      {
        path: '/qiwei',
        name: 'qiwei',
        component: () => import('@/pages/qiwei.vue'),
        meta: {
          title: '飞钉-企微业务线'
        }
      },
      {
        path: '/feishu',
        name: 'feishu',
        component: () => import('@/pages/feishu.vue'),
        meta: {
          title: '飞钉-飞书业务线'
        }
      },
      {
        path: '/dingding',
        name: 'dingding',
        component: () => import('@/pages/dingding.vue'),
        meta: {
          title: '飞钉-钉钉业务线'
        }
      },
      {
        path: '/detail',
        name: 'detail',
        component: () => import('@/pages/detail.vue')
      }
    ]
  }
)

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title
  }
  next()
})
export default router
