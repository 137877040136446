<template>
  <div id="app" style="cursor: default;">
    <router-view></router-view>

    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>
<script>
setTimeout(() => {
      (function () {
        var _53code = document.createElement("script");
        _53code.src =
          "https://tb.53kf.com/code/code/f09186e91eb7a61cfb2584a7983a9fb11/2";
        var s = document.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(_53code, s);
      })();
    }, 3000);

    </script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  // box-sizing: border-box;
  color: rgba(38, 38, 38, 1);
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  // margin-top: 60px;
}
</style>
